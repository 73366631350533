"use client";
import { motion } from "framer-motion";
import { HeroHighlight, Highlight } from "./components/ui/hero-highlight";
import { Button } from "./components/ui/button";
import TwitterIcon from "./components/icons/twitter";

export default function HeroHighlightDemo() {
  return (
    <HeroHighlight containerClassName="min-h-dvh items-center text-center py-10 px-10">
      <div className="flex flex-col items-center justify-center">
        <img src='/logo-512.png' height={100} width={100} alt="logo" />
        <motion.h1
          initial={{
            opacity: 0,
            y: 20,
          }}
          animate={{
            opacity: 1,
            y: [20, -5, 0],
          }}
          transition={{
            duration: 0.5,
            ease: [0.4, 0.0, 0.2, 1],
          }}
          className="text-3xl md:text-5xl lg:text-7xl py-2 mt-4 font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-purple-500"
        >
          JackDoge
      </motion.h1>
      </div>
      <div className="text-base md:text-lg">
        <div className=" mt-4 text-white">Jack is Pacman&apos;s doge name which has very good luck.</div>
        <div className="mt-0 text-white">
          Owning some jack doge token may boost your jackpot win rate!
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 justify-center items-center mt-10">
          <Button size='lg' asChild className="text-lg gap-2 border border-white">
            <a href="https://tomoon.org/token/0x883E00A5C07c6411D6FD22621ed002149E4579c1" target="_blank">
            <img src="/tomoon.svg" className="h-6 w-6 " />
            Trade on Tomoon
            </a>
          </Button>
          <Button size='lg' asChild className="text-lg gap-2 border-primary" variant='outline'>
            <a href="https://twitter.com/jackdoge_win" target="_blank">
            <TwitterIcon className="h-6 w-6" />
            Twitter
            </a>
          </Button>
        </div>
      <div className="flex flex-col items-center justify-center mt-20">
        <img src='/dog.gif' height={75} width={75} alt="jackdoge" />
        <div>
          <Highlight className="">A dog with a championship medal</Highlight>
        </div>
      </div>
      <div className="text-muted-foreground text-sm mt-10">
        ©2024 <a className="hover:underline" href="https://jackdoge.win">jackdoge.win</a>
      </div>

    </HeroHighlight>
  );
}
